window.angular.module('MyHippoProducer.Components').directive('ratingStars', function () {
    return {
        restrict: 'E',
        transclude: true,
        scope: {
            policyOrLead: '=',
            currentPolicyLead: '=',
        },
        templateUrl: 'components/rating-stars/rating-stars.html',
        controller: function ($scope, $mdDialog) {
            const mapSegmentation = {
                'A+': 5,
                'A': 4,
                'B': 3,
                'C': 0
            };
            const mapStarNumber = (segmentation) => {
                if(!segmentation) {
                    return 0;
                }
                return mapSegmentation[segmentation];
            };
            $scope.stars = new Array(mapStarNumber($scope.policyOrLead.segmentation));
            $scope.hoverOut = function(){
                this.showTooltip = false;
                //Need to reset object because $$watch only fires when a variable changes values
                $scope.policyOrLead.revisitRatingTooltip = $scope.policyOrLead.id;
            };
            $scope.tooltipPosition = $scope.policyOrLead && $scope.policyOrLead.policy_number ? {
                'bottom': '25px',
                'right': '30px',
            } : {
                'bottom': '35px',
                'right': '120px',
            };
            $scope.$watch('currentPolicyLead', (newValue) => {
                if(newValue) {
                    $scope.showTooltip = newValue.id === $scope.policyOrLead.id || newValue.revisitRatingTooltip === $scope.policyOrLead.id;
                }
            });
            $scope.showModal = () => {
                $mdDialog.show({
                    parent: angular.element(document.body),
                    template: `<rating-stars-modal></rating-stars-modal>`
                });
            };
        }
    };
});
